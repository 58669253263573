<template>
    <div>
        <ViewTitle :title="`客戶服務 | 客戶查詢`" class="viewtitle-container">
        </ViewTitle>
        <CustomerFilter
            :isLoading="isTableLoading"
            @show-data-table="showDataTable"
            v-model:filterPayloadProp="filterPayload"
            v-model:filterLogProp="filterLog"
        />
        <CustomerTable
            :listData="listData"
            :isLoading="isTableLoading"
            :filterLog="filterLog"
            :payload="filterPayload"
            @showsuppliersyncmodal="showSupplierSyncModal"
        />
    </div>
    <!-- <SupplierSyncModal
        page="customer"
        :customerId="customerId"
        v-model="isShowSupplierSyncModal"
    /> -->
</template>

<script>
// import _ from 'lodash'
import ViewTitle from '@/components/ViewTitle.vue'
import CustomerFilter from '@/containers/tia/CustomerFilter.vue'
import CustomerTable from '@/containers/tia/CustomerTable.vue'
// import SupplierSyncModal from '@/containers/tia/SupplierSyncModal.vue'
import {
    getTiaCustomerListAPI
    // getTiaCustomerDetailListAPI
} from '@/assets/javascripts/api'

export default {
    name: 'Customer',
    components: {
        ViewTitle,
        CustomerFilter,
        CustomerTable
        // SupplierSyncModal
    },
    methods: {
        showDataTable: async function () {
            if (this.filterPayload.manCodes.length <= 0) return
            try {
                if (!this.$hasPermission('list')) {
                    throw new Error('noPermission')
                }

                this.isTableLoading = true
                this.$showLoading()
                let response = await this.$getResponse(
                    this.filterPayload,
                    this.permissionInfo,
                    getTiaCustomerListAPI
                )
                this.listData = response.data
            } catch (error) {
                this.listData = []
            } finally {
                this.isTableLoading = false
                this.$hideLoading()
            }
        },
        showSupplierSyncModal: function (val) {
            this.customerId = val
            this.isShowSupplierSyncModal = true
        }
    },
    computed: {
        permissionInfo: function () {
            return { apiAlias: 'list', apiName: '客戶列表' }
        }
    },
    data() {
        return {
            isTableLoading: false,
            isShowEventModel: false,
            isShowSupplierSyncModal: false,
            customerId: null,
            filterPayload: {
                manCodes: [],
                birthdayType: 'all',
                dateStart: '',
                dateEnd: ''
            },
            listData: [],
            filterLog: {}
        }
    }
}
</script>

<style lang="scss" scoped></style>
